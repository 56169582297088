 import React from 'react';
 import DefaultLayout from '../../../layouts/DefaultLayout';
 import TemplateColumn, { TemplateColumnWithSidebarContainer } from '../../../layouts/TemplateColumn';
 import { PageHeader, PageSection, RelatedContent, CrossLink, IndicationPageIntro, AnchorLinks, TextBlock, SecondaryCallout, PageSubSection, DataTable } from '../../../components';
 import GlobalSafetyData, { globalSafetyBlock } from './_global_safety_data'
 import gastric599Table from "../../../assets/OGA-559-small-V3.svg"
 import { jobCodes } from '../../../util/data/jobCodes';
 import { StaticImage } from 'gatsby-plugin-image';

 // SEO INFORMATION
 const pageMeta = {
     title: `Adverse Reactions in Patients With Advanced Gastric or GEJ Cancer | HCP`,
     keywords: `keytruda gastric adenocarcinoma adverse reactions, keytruda gej cancer adverse reactions`,
     description: `Health care professionals can review adverse reactions with KEYTRUDA® (pembrolizumab) for patients with locally advanced unresectable or metastatic HER2-negative gastric or gastroesophageal junction (GEJ) adenocarcinoma.`,
     schemaJsonLD: [
         `{"@context":"https://schema.org/","url":"https://www.keytrudahcp.com/safety/adverse-reactions/gastric-adenocarcinoma/","@type":"MedicalWebPage","name":"Most Common Adverse Reactions in KEYNOTE-859","description":"In KEYNOTE-859, when KEYTRUDA was administered in combination with fluoropyrimidine- and platinum-containing chemotherapy, serious adverse reactions occurred in 45% of 785 patients. Serious adverse reactions in >2% of patients included pneumonia (4.1%), diarrhea (3.9%), hemorrhage (3.9%), and vomiting (2.4%). Fatal adverse reactions occurred in 8% of patients who received KEYTRUDA, including infection (2.3%) and thromboembolism (1.3%). KEYTRUDA was permanently discontinued due to adverse reactions in 15% of patients. The most common adverse reactions resulting in permanent discontinuation of KEYTRUDA (≥1%) were infections (1.8%) and diarrhea (1.0%). The most common adverse reactions (reported in ≥20%) in patients receiving KEYTRUDA in combination with chemotherapy were peripheral neuropathy (47%), nausea (46%), fatigue (40%), diarrhea (36%), vomiting (34%), decreased appetite (29%), abdominal pain (26%), palmar-plantar erythrodysesthesia syndrome (25%), constipation (22%), and weight loss (20%)."}`,
         '{"@context":"https://schema.org/","url":"https://www.keytrudahcp.com/safety/adverse-reactions/gastric-adenocarcinoma/","@type":"MedicalAudience","audienceType":"health care professionals","geographicArea":"This site is intended for health care professionals of the United States, its territories, and Puerto Rico."}'
     ]
 }

// image alts - so they do not repeat in mobile & desktop
const gastricSafetyAlt = "Adverse Reactions in ≥20% of Patients in KEYNOTE-859"

 //from /src/util/data/indications.js
 const indicationId = 18;
 const jobCode = jobCodes[18].jobCode;

 const relatedContent = [
     {
         section: 'Resources',
         link: {
             url: '/resources/mechanism-of-action/',
             label: 'Mechanism of Action'
         }
     },
     {
         section: 'Resources',
         link: {
             url: 'https://www.merckaccessprogram-keytruda.com/',
             label: 'Access & Reimbursement Support'
         }
     }
 ];

 const crossLinkContent = {
     sections : [
         {
             heading: "Related Information",
             items: [
                 { text: 'Treatment Monitoring & Management', url: '/safety/monitoring-managing-adverse-reactions/' },
                 { text: 'Dose Modifications', url: '/dosing/dose-modifications/' },
                         ]
         },
     ],
     interestLinks : {
         heading: "You Might Be Interested In",
         items: [
             { eyebrow: 'Resources', text: 'Mechanism of Action', url: '/resources/mechanism-of-action/' },
         ],
     }
 };

 const anchorLinkModelData = [
     {label: 'Incidence of Selected Immune-Mediated Adverse Reactions'},
     {label: 'Management of Selected Immune-Mediated Adverse Reactions'}, 
     { label: 'Most Common Adverse Reactions in KEYNOTE&#8288;-&#8288;859' }
 ]

const key859footnotes = [
    {
        label: "c.",
        text: "Graded Per NCI CTCAE v4.03"
    },
    {
        label: "d.",
        text: "Includes dysesthesia, hyperesthesia, hypoesthesia, neuralgia, neuropathy peripheral, paresthesia, peripheral sensory neuropathy, peripheral motor neuropathy, polyneuropathy"
    },
    {
        label: "e.",
        text: "Includes abdominal discomfort, abdominal pain, abdominal pain lower, abdominal tenderness, abdominal pain upper, epigastric discomfort, gastrointestinal pain"
    },
    {
        label: "f.",
        text: "Includes asthenia, fatigue"
    },
]
 
 const Page = ({ location }) => {

     return (
         <DefaultLayout indicationId={indicationId} jobCode={jobCode} pageMeta={ pageMeta } location={ location }>
             <div data-template-name="template-e">
                 {/* Begin Intro Column */}
                 <TemplateColumn id="template-e-intro" maxWidth={824}>
                     <PageHeader title="Selected Adverse Reactions" />
                     <PageSection bgColor="white">
                         <IndicationPageIntro indicationId={indicationId} />
                     </PageSection>
                 </TemplateColumn>
                 {/* End Intro Column */}

                 <TemplateColumnWithSidebarContainer>
                     {/* Begin Main Column */}
                     <TemplateColumn id="template-e-main">
                         <PageSection bgColor="cloud" title="Severe and Fatal Immune-Mediated Adverse Reactions">
                             <TextBlock>{globalSafetyBlock}</TextBlock>
                             <AnchorLinks items={anchorLinkModelData} />
                         </PageSection>

                         <GlobalSafetyData />

                         <PageSection title={anchorLinkModelData[2].label}>
                             <TextBlock>
                                 <p>The safety of KEYTRUDA was evaluated in 1,572 patients with HER2-negative gastric or GEJ cancer enrolled in KEYNOTE&#8288;-&#8288;859, which included 785 patients treated with KEYTRUDA 200&nbsp;mg and FP (n=106) or CAPOX (n=674) every 3 weeks, compared to 787 patients who received placebo and FP (n=107) or CAPOX (n=679) every 3 weeks.</p>
                                 <p>The median duration of exposure to KEYTRUDA was 6.2 months (range: 1 day to 33.7 months).
Serious adverse reactions occurred in 45% of patients receiving KEYTRUDA. Serious adverse reactions in >2% of patients included pneumonia (4.1%), diarrhea (3.9%), hemorrhage (3.9%), and vomiting (2.4%).</p>
                                 <p>Fatal adverse reactions occurred in 8% of patients who received KEYTRUDA, including infection (2.3%) and thromboembolism (1.3%).</p>
                                <p>KEYTRUDA was permanently discontinued due to adverse reactions in 15% of patients. Adverse reactions resulting in permanent discontinuation of KEYTRUDA in ≥1% were infection (1.8%) and diarrhea (1.0%).</p>
                                <p>Dosage interruptions of KEYTRUDA due to an adverse reaction occurred in 65% of patients. Adverse reactions or laboratory abnormalities leading to interruption of KEYTRUDA (≥2%) were neutropenia (21%), thrombocytopenia (13%), diarrhea (5.5%), fatigue (4.8%), infection (4.8%), anemia (4.5%), increased AST (4.3%), increased ALT (3.8%), increased blood bilirubin (3.3%), white blood cell count decreased (2.2%), nausea (2%), palmar-plantar erythrodysesthesia syndrome (2%), and vomiting (2%).</p>
                             </TextBlock>
                                <PageSubSection title="Safety Profile in KEYNOTE&#8288;-&#8288;859">
                                    <DataTable 
                                        mobileImage={() => <img src={gastric599Table} style={{width:'100%', maxWidth:'500px', margin: '0 auto'}} alt={gastricSafetyAlt}/>}
                                        desktopImage={() => <StaticImage quality={90} placeholder="DOMINANT_COLOR" src="../../../assets/gastric-safety-859-desktop-v4.png" alt={gastricSafetyAlt}/>}
                                        title="Adverse Reactions in ≥20% of Patients in KEYNOTE&#8288;-&#8288;859"
                                        footnotes={key859footnotes}
                                    />
                                </PageSubSection>
                         </PageSection>
                     </TemplateColumn>
                     {/* End Main Column */}

                     <TemplateColumn id="template-e-sidebar">
                         <PageSection bgColor="mist">
                             <CrossLink {...crossLinkContent} />
                         </PageSection>
                     </TemplateColumn>

                 </TemplateColumnWithSidebarContainer>

                 <TemplateColumn id="template-e-secondary">
                     <PageSection bgColor="keytrudaGreen">
                         <RelatedContent items={relatedContent} />
                     </PageSection>
                 </TemplateColumn>
             </div>
         </DefaultLayout>
     );
 };

 export default Page;
